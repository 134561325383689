import React, { useState, useEffect } from 'react';

interface MentorshipCardProps {
  id: number;
  initialTitle?: string;
  initialTagline?: string;
  initialType?: string;
  initialTargetAudience?: string;
  initialDuration?: string;
  initialPrice?: string;
  initialDescription?: string;
  initialObjectif?: string;
  onSave?: (id: number, data: any) => void;
  onCancel?: (id: number) => void;
}

const FormInput = ({ label, value, onChange, placeholder = "" }) => (
  <div className="flex flex-col">
    <label className="mb-2 custom-text-[poppins-500-16px] text-[#111827]">
      {label}
    </label>
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="w-full rounded-[15px] px-4 py-3 focus:ring-2 focus:ring-[#407EC9] shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] border-none outline-none bg-white custom-text-[poppins-400-16px]"
    />
  </div>
);

const FormTextarea = ({ label, value, onChange, placeholder = "", rows = 6 }) => (
  <div className="flex flex-col">
    <label className="mb-2 custom-text-[poppins-500-16px] text-[#111827]">
      {label}
    </label>
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      className="w-full rounded-[15px] px-4 py-3 focus:ring-2 focus:ring-[#407EC9] shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] border-none outline-none bg-white resize-none custom-text-[poppins-400-16px]"
    />
  </div>
);


const MentorshipCard: React.FC<MentorshipCardProps> = ({
  id,
  initialTitle = "Consulting & Expertise",
  initialTagline = "Boostez votre valeur sur le marché",
  initialType = "Accompagnement en groupe",
  initialTargetAudience = "Freelances & consultants",
  initialDuration = "1 mois",
  initialPrice = "399€",
  initialDescription = `√ Sessions en groupe pour apprendre des autres et échanger des bonnes pratiques
√ Définir une offre d’expert et un positionnement premium
√ Améliorer ses compétences techniques et stratégiques
√ Stratégies pour trouver des missions mieux rémunérées
√ Optimisation de votre LinkedIn & présence en ligne
√ Négociation & closing : facturer au juste prix`,
  initialObjectif = `Gagnez en expertise, augmentez votre valeur et attirez des clients à plus forte valeur ajoutée.`,
  onSave,
  onCancel,
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [tagline, setTagline] = useState(initialTagline);
  const [type, setType] = useState(initialType);
  const [targetAudience, setTargetAudience] = useState(initialTargetAudience);
  const [duration, setDuration] = useState(initialDuration);
  const [price, setPrice] = useState(initialPrice);
  const [description, setDescription] = useState(initialDescription);
  const [objectif, setObjectif] = useState(initialObjectif);

  useEffect(() => {
    setTitle(initialTitle);
    setTagline(initialTagline);
    setType(initialType);
    setTargetAudience(initialTargetAudience);
    setDuration(initialDuration);
    setPrice(initialPrice);
    setDescription(initialDescription);
    setObjectif(initialObjectif);
  }, [initialTitle, initialTagline, initialType, initialTargetAudience, initialDuration, initialPrice, initialDescription, initialObjectif]);

  const handleSaveClick = () => {
    if (onSave) {
      onSave(id, { title, tagline, type, targetAudience, duration, price, description, objectif });
    }
  };

  const handleCancelClick = () => {
    if (onCancel) {
      onCancel(id);
    }
  };

  return (
    <div className="bg-white rounded-[15px] shadow-[0px_7px_29px_#64646f33] p-[79px] w-full">
      <h3 className="mb-6 custom-text-[poppins-600-32px] text-[#141414]">
        Template of services to follow
      </h3>
      <img src={require("assets/mentor-template-of-services.png")} alt="Mentorship" className="mb-6 w-full h-auto rounded-[15px]" />
      <h3 className="mb-6 custom-text-[poppins-600-32px] text-[#141414]">
        Edit your services
      </h3>
      <div className="grid grid-cols-1 gap-y-6 gap-x-8 mb-6 md:grid-cols-2">
        <FormInput label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
        <FormInput label="Tagline" value={tagline} onChange={(e) => setTagline(e.target.value)} />
        <FormInput label="Type" value={type} onChange={(e) => setType(e.target.value)} />
        <FormInput label="For who ?" value={targetAudience} onChange={(e) => setTargetAudience(e.target.value)} />
        <FormInput label="Duration" value={duration} onChange={(e) => setDuration(e.target.value)} />
        <FormInput label="Pricing" value={price} onChange={(e) => setPrice(e.target.value)} />
      </div>
      <div className="mb-8">
        <FormTextarea label="More details" value={description} onChange={(e) => setDescription(e.target.value)} />
      </div>
      <div className="mb-8">
        <FormInput label="Objectif" value={objectif} onChange={(e) => setObjectif(e.target.value)} />
      </div>

      <div className="flex gap-4 justify-end">
         <button
            onClick={handleCancelClick}
            className="rounded-lg border border-[#407EC9] px-[34px] py-[14px] text-[#407EC9] transition-colors custom-text-[poppins-400-20px] hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveClick}
            className="rounded-lg bg-[#407EC9] px-[34px] py-[14px] text-white transition-colors custom-text-[poppins-400-20px] hover:bg-[#3569A7]"
          >
            Save changes
          </button>
      </div>
    </div>
  );
};

export default MentorshipCard;
