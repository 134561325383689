import { Button, FormControl, FormHelperText, IconButton } from "@mui/material";
import LoadingImage from "components/LoadingImage";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Tag = ({ text, tags, setTags }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);
  const handleSubmit = () => {
    setTags(tags.map((tag) => (tag === text ? editedText : tag)));
    setIsEditing(false);
  };

  const handleDelete = () => {
    setTags(tags.filter((tag) => tag !== text));
  };

  return (
    <div className="flex items-center justify-center gap-2 rounded-lg bg-[#E5EEF9] px-[13px] py-[9px] text-[#407EC9]">
      {isEditing ? (
        <>
          <div className="relative w-auto">
            <input
              type="text"
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              className="w-[1ch] min-w-[1ch] bg-transparent outline-none"
              style={{ width: `${editedText.length * 0.9}ch` }}
              autoFocus
            />
          </div>
          <IconButton
            onClick={handleSubmit}
            size="small"
            className="hover:bg-blue-dark"
          >
            <svg
              className="w-4 h-4 text-blue-light"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </IconButton>
        </>
      ) : (
        <>
          <span onClick={() => setIsEditing(true)}>{text}</span>
          <IconButton
            onClick={handleDelete}
            size="small"
            className="hover:bg-blue-dark"
          >
            <img
              loading="lazy"
              src={require("assets/close-blue.svg").default}
              alt=""
              className="my-auto aspect-[1.12] w-[9px] shrink-0"
            />
          </IconButton>
        </>
      )}
    </div>
  );
};

const PersonalInterestsSection = ({
  tags,
  setTags,
  handleUpdateTags,
  hasSubmitError = false,
  hasSubmitSuccess = false,
  isLoading = false,
  showUpdateButton = true,
}) => {
  const { t } = useTranslation();

  return (
    <section className="flex max-w-[1049px] flex-col pr-4">
      <h2 className="w-full text-3xl font-semibold text-neutral-900 max-md:max-w-full">
        {t("tags-and-interests")}{" "}
      </h2>
      <hr className="mt-1 h-[1px] border-black bg-black opacity-30" />
      <div className={`${showUpdateButton ? null :"flex-col"} flex mt-9 w-full tags-interests max-md:max-w-full max-md:flex-wrap`}>
        <div className="tags-container my-auto ml-[15px] flex !w-full !max-w-[1200px] flex-auto flex-wrap gap-2.5 text-base leading-4 text-blue max-md:max-w-full">
          {tags.map((tag) => {
            return <Tag tags={tags} setTags={setTags} key={tag} text={tag} />;
          })}
          <span
            className="custom-text-poppins-300-16px my-auto ml-[53px] !w-[67px] cursor-pointer text-black hover:text-gray-400"
            onClick={() => {
              setTags(tags.concat("new tag - click to modify"));
            }}
          >
            {t("add-plus")}{" "}
          </span>
        </div>

        <FormControl fullWidth>
          {
            showUpdateButton ? (<Button
              variant="contained"
              onClick={handleUpdateTags}
              className={`!h-[53px] !w-[161px] !justify-center !self-center !whitespace-nowrap !rounded-2xl !p-0 !text-center !text-sm !font-semibold !normal-case !text-white !shadow-lg ${isLoading ? "bg-gray-900" : ""} !hover:bg-blue-dark bg-blue-dark`}
              disabled={isLoading}
            >
              {isLoading ? (
                <LoadingImage className="!h-full !w-auto" />
              ) : (
                <h4 className="text !custom-text-poppins-600-14_4px">
                  {t("update")}
                </h4>
              )}
            </Button>) : null
          }
          <FormHelperText className="self-center !text-red">
            {hasSubmitError ? t("an-error-has-occured-please-try-again") : ""}
          </FormHelperText>
          <FormHelperText className="self-center !text-green">
            {hasSubmitSuccess ? t("successfully-updated") : ""}
          </FormHelperText>
        </FormControl>
      </div>
    </section>
  );
};

export default PersonalInterestsSection;
