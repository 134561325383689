import { useAppSelector } from "store/hooks";
import LoadingImage from "components/LoadingImage";
import { ChangeEvent, useState, useEffect } from "react"; // Added useEffect
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIGetMentors, APIUploadAvatar, AVATARGetUserAvatarUrl, APIUpdateMentorByID } from "utils/api";
import { Link } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ImageCropProvider from "components/Cropper/ImageCropProvider";
import ProfileAvatar from "components/ProfileAvatar";
import MentorshipCard from "./MentorshipCard";
import PersonalInterestsSection from "../PersonalInterestsSection";
const LinkedInIcon = () => <span>[in]</span>;
const GithubIcon = () => <span>[git]</span>;
const TwitterIcon = () => <span>[X]</span>;
const FacebookIcon = () => <span>[f]</span>;
const InstagramIcon = () => <span>[ig]</span>;

const CustomToggle = ({ label, checked, onChange }) => {
  return (
    <div className="flex items-center">
      <label className="inline-flex relative items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={checked}
          onChange={onChange}
        />
        <div className={`
              relative w-[40px] h-[20px]
              rounded-full
              bg-gradient-to-b from-[#B8B8B8] to-[#DCDCDC]
              shadow-sm
              peer-checked:bg-[#407EC9] peer-checked:from-[#407EC9] peer-checked:to-[#407EC9]
              transition-all duration-200
            `}></div>
        <div className={`absolute bg-gradient-to-b from-white to-gray-50 rounded-full shadow-sm transition-transform duration-200 transform left-[1px] top-[1px] w-[17px] h-[17px] peer-checked:translate-x-5`}></div>
        <span className="ml-[8px] text-sm font-medium text-gray-500">
          {label}
        </span>
      </label>
    </div>
  );
};

const EditMentorProfilePage = () => {
  const [bioCharCount, setBioCharCount] = useState(0);
  const user = useAppSelector((state) => state.auth.user_me);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  const [showLabs, setShowLabs] = useState(true);
  const [showInviteMentors, setShowInviteMentors] = useState(true);
  const [showLegalInfo, setShowLegalInfo] = useState(true);

  const [mentorships, setMentorships] = useState([]);

  const queryClient = useQueryClient(); // Get query client

  const mentorQuery = useQuery({
    queryKey: ["mentor_me"],
    queryFn: () => APIGetMentors(null, true, user.id).then((res) => res.data[0] || null),
    enabled: isLoggedIn,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });

  const validationSchema = Yup.object().shape({
    profile_id: Yup.string().required('Page ID is required').min(3, 'Page ID must be at least 3 characters'),
    lang: Yup.string().required('Language is required'),
    title: Yup.string(), //.required('Title is required'),
    tagline: Yup.string(),
    mentor_description: Yup.string().required('Bio is required').max(400, 'Bio cannot exceed 400 characters'),
    linkedinUrl: Yup.string().url('Invalid LinkedIn URL'),
    githubUrl: Yup.string().url('Invalid Github URL'),
    twitterUrl: Yup.string().url('Invalid Twitter URL'),
    facebookUrl: Yup.string().url('Invalid Facebook URL'),
    instagramUrl: Yup.string().url('Invalid Instagram URL'),
    tags: Yup.array().of(Yup.string()),
  });

  const updateMentorMutation = useMutation({
    mutationFn: (payload: any) => {
      if (!mentorQuery.data?.id) {
        return Promise.reject(new Error("Mentor ID not found"));
      }

      return APIUpdateMentorByID(mentorQuery.data.id, payload);
    },
    onSuccess: (data) => {
      console.log("Mentor updated successfully:", data);
      queryClient.invalidateQueries({ queryKey: ['mentor_me'] });
      // TODO: add user feedback (enotification or something)
      alert("Mentor updated successfully");
    },
    onError: (error) => {
      console.error("Error updating mentor:", error);
      // TODO: user feedback
      alert("Error updating mentor");
    },
  });


  /**
   * Avatar
   */
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const [avatarError, setAvatarError] = useState("");

  const handleUploadAvatar = async () => {
    if (croppedImageFile) {
      setAvatarError("");
      setIsAvatarLoading(true);
      APIUploadAvatar(user.id, croppedImageFile)
        .then((res) => {
          setAvatarError("");
          setIsAvatarLoading(false);
          setCroppedImageFile(null);
        })
        .catch((err) => {
          console.log(err);
          setAvatarError("something-went-wrong-please-try-again");
          setIsAvatarLoading(false);
        });
    }
  };

  useEffect(() => {
    if (mentorQuery.data) {
      setBioCharCount(mentorQuery.data.mentor_description?.length || 0);
    }
  }, [mentorQuery.data]);


  const handleBioChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value
    if (text.length <= 400) {
      setBioCharCount(text.length)
    }
  }

  const handleAddMentorship = () => {
    setMentorships(prev => [
      ...prev,
      {
        id: Date.now(),
        initialTitle: "Consulting & Expertise",
        initialTagline: "Boostez votre valeur sur le marché",
        initialDuration: "1 mois",
        initialPrice: "399€",
        initialTargetAudience: "Freelances & consultants",
        initialDescription: `√ Sessions en groupe pour apprendre des autres et échanger des bonnes pratiques
√ Définir une offre d’expert et un positionnement premium
√ Améliorer ses compétences techniques et stratégiques
√ Stratégies pour trouver des missions mieux rémunérées
√ Optimisation de votre LinkedIn & présence en ligne
√ Négociation & closing : facturer au juste prix`,
        initialType: "Accompagnement en groupe",
        initialObjectif: `Gagnez en expertise, augmentez votre valeur et attirez des clients à plus forte valeur ajoutée.`,
      }
    ]);
  };

  const handleSaveMentorship = (id, updatedData) => {
    setMentorships(prev =>
      prev.map(m =>
        m.id === id
          ? {
            ...m, ...updatedData,
            initialTitle: updatedData.title,
            initialTagline: updatedData.tagline,
            initialType: updatedData.type,
            initialTargetAudience: updatedData.targetAudience,
            initialDuration: updatedData.duration,
            initialPrice: updatedData.price,
            initialDescription: updatedData.description,
          }
          : m
      )
    );
  };

  const handleCancelMentorship = (id) => {
    setMentorships(prev => prev.filter(m => m.id !== id));
  };

  // Note: initialized as empty and will be updated by enableReinitialize when mentorQuery.data loads
  const initialValues = {
    profile_id: mentorQuery.data?.profile_id || "",
    lang: mentorQuery.data?.lang || 'en',
    title: mentorQuery.data?.title || "",
    tagline: mentorQuery.data?.tagline || "",
    mentor_description: mentorQuery.data?.mentor_description || "",
    linkedinUrl: mentorQuery.data?.socials?.find(s => s.platform === 'linkedin')?.url || "",
    showLinkedin: mentorQuery.data?.socials?.find(s => s.platform === 'linkedin')?.visible ?? true,
    githubUrl: mentorQuery.data?.socials?.find(s => s.platform === 'github')?.url || "",
    showGithub: mentorQuery.data?.socials?.find(s => s.platform === 'github')?.visible ?? true,
    twitterUrl: mentorQuery.data?.socials?.find(s => s.platform === 'twitter')?.url || "",
    showTwitter: mentorQuery.data?.socials?.find(s => s.platform === 'twitter')?.visible ?? false,
    facebookUrl: mentorQuery.data?.socials?.find(s => s.platform === 'facebook')?.url || "",
    showFacebook: mentorQuery.data?.socials?.find(s => s.platform === 'facebook')?.visible ?? false,
    instagramUrl: mentorQuery.data?.socials?.find(s => s.platform === 'instagram')?.url || "",
    showInstagram: mentorQuery.data?.socials?.find(s => s.platform === 'instagram')?.visible ?? false,
    tags: mentorQuery.data?.tags || [],
    feature_flag_testimonials: mentorQuery.data?.feature_flag_testimonials ?? true,
    feature_flag_mentorship_services: mentorQuery.data?.feature_flag_mentorship_services ?? true,
  };


  // Form submission handler
  const handleFormSubmit = (values) => {
    console.log("Form values on submit:", values);

    const socialsPayload = [
      { platform: 'linkedin', url: values.linkedinUrl, visible: values.showLinkedin },
      { platform: 'github', url: values.githubUrl, visible: values.showGithub },
      { platform: 'twitter', url: values.twitterUrl, visible: values.showTwitter },
      { platform: 'facebook', url: values.facebookUrl, visible: values.showFacebook },
      { platform: 'instagram', url: values.instagramUrl, visible: values.showInstagram },
    ].filter(social => social.url);

    const payload = {
      profile_id: values.profile_id,
      lang: values.lang,
      mentor_firstname: user.firstname,
      mentor_lastname: user.lastname,
      title: values.title,
      tagline: values.tagline,
      mentor_description: values.mentor_description,
      socials: socialsPayload,
      tags: values.tags,
      feature_flag_testimonials: values.feature_flag_testimonials,
      feature_flag_mentorship_services: values.feature_flag_mentorship_services,
      user_id: user.id,
    };

    console.log("API Payload:", payload);
    updateMentorMutation.mutate(payload);
  };

  return (
    <section className="flex w-full h-full border-l-[1px] border-[#F5F5F5]">
      {
        mentorQuery.isLoading || !user ? (
          <LoadingImage />
        ) :
          (<>
            <div className="flex flex-col pt-[56px] px-[46px] h-full w-full">
              <div className="flex flex-col items-start justify-between mb-8 md:flex-row md:items-center text-[#111827]">
                <div>
                  <h1 className="custom-text-[volkhov-700-35px]">
                    Hello {user.firstname}
                  </h1>
                  <p className="custom-text-[poppins-400-18px]">
                    Update your Mentor page informations here !
                  </p>
                </div>
              </div>
              {(isLoggedIn && user && mentorQuery.data && mentorQuery.data.id) ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleFormSubmit}
                  enableReinitialize={true}
                >
                  {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                    <Form>
                      <div className="flex gap-4 justify-end mb-4">
                        <Link target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_LANDING_URL}/mentor/${mentorQuery.data.profile_id}`} className="flex items-center gap-2 rounded-lg border border-[#407EC9] !no-underline px-6 py-3 text-[#407EC9] transition-colors hover:bg-gray-50">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                            <circle cx="12" cy="12" r="3" />
                          </svg>
                          <span className="custom-text-[poppins-500-15px]">
                            Preview Page
                          </span>
                        </Link>
                        <button
                          type="submit"
                          className="rounded-lg bg-[#407EC9] px-6 py-3 text-white transition-colors custom-text-[poppins-500-15px] hover:bg-[#3569A7] disabled:opacity-50"
                          disabled={isSubmitting || updateMentorMutation.isPending}
                        >
                          <span className="flex gap-2 items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M20 6 9 17l-5-5" />
                            </svg>
                            {isSubmitting || updateMentorMutation.isPending ? 'Saving...' : 'Save Changes'}
                          </span>
                        </button>
                      </div>

                      <div className="grid grid-cols-1 gap-8 md:grid-cols-[2fr_1fr]">
                        <div className="space-y-6">
                          {/* Profile ID Section */}
                          <div>
                            <label
                              htmlFor="profile_id"
                              className="mb-2 block text-lg custom-text-[poppins-500-16px] text-[#111827]"
                            >
                              Edit your Page ID
                            </label>
                            <div className="flex relative items-center">
                              <div className={`flex items-center w-full rounded-[15px] px-4 py-3 shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] bg-white focus-within:ring-2 focus-within:ring-[#407EC9] ${errors.profile_id && touched.profile_id ? 'ring-2 ring-red-500' : ''}`}>
                                <span className="text-gray-500 custom-text-[poppins-400-16px]">
                                  https://scalyz.com/mentors/
                                </span>
                                <Field
                                  id="profile_id"
                                  name="profile_id" // Formik field name
                                  type="text"
                                  // value={pageId} // Controlled by Formik
                                  // onChange={(e) => { // Controlled by Formik
                                  //   setPageId(e.target.value);
                                  //   // TODO: Implement actual validation logic (e.g., API call)
                                  //   // For now, just reset validation state on change
                                  //   setIsPageIdValid(null);
                                  //   setPageIdValidationMessage("");
                                  //   // Example validation (remove later):
                                  //   if (e.target.value === "aminebenasker") {
                                  //     setIsPageIdValid(false);
                                  //     setPageIdValidationMessage("Name taken");
                                  //   } else if (e.target.value.length > 3) {
                                  //     setIsPageIdValid(true);
                                  //     setPageIdValidationMessage("");
                                  //   } else {
                                  //     setIsPageIdValid(null);
                                  //     setPageIdValidationMessage("");
                                  //   }
                                  // }}
                                  placeholder="your-unique-id"
                                  className="flex-grow ml-1 bg-transparent border-none outline-none custom-text-[poppins-400-16px] text-[#407EC9]"
                                />
                              </div>
                              {/* Validation Icons - TODO: Integrate with Formik validation state if needed, or remove if ErrorMessage is sufficient */}
                              {/* {isPageIdValid === true && ( ... )} */}
                              {/* {isPageIdValid === false && ( ... )} */}
                            </div>
                            <ErrorMessage name="profile_id" component="p" className="mt-1 text-sm text-red-500 custom-text-[poppins-400-14px] text-right mr-[27px]" />
                            {/* <p className="mt-1 text-sm text-red-500 custom-text-[poppins-400-14px] text-right mr-[27px]">
                              {pageIdValidationMessage ? pageIdValidationMessage : " "}
                            </p> */}
                          </div>

                          {/* Language Selection Section */}
                          <div>
                            <h2 className="mb-4 custom-text-[poppins-500-16px] text-[#111827]">
                              Start by choosing the page language
                            </h2>
                            <div className="inline-flex overflow-hidden rounded-[15px] shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)]">
                              <button
                                type="button" // Prevent form submission
                                className={`px-8 py-4 border-r-[#6b728034] border-r-[1px] ${values.lang === "en" ? "bg-white custom-text-[poppins-600-16px] text-[#407EC9]" : "bg-gray-50 text-[#6B7280]"}`}
                                onClick={() => setFieldValue("lang", "en")} // Update Formik state
                              >
                                English
                              </button>
                              <button
                                type="button" // Prevent form submission
                                className={`px-8 py-4 ${values.lang === "fr" ? "bg-white custom-text-[poppins-600-16px] text-[#407EC9]" : "bg-gray-50 text-[#6B7280]"}`}
                                onClick={() => setFieldValue("lang", "fr")} // Update Formik state
                              >
                                French
                              </button>
                            </div>
                            <ErrorMessage name="lang" component="p" className="mt-1 text-sm text-red-500" />
                          </div>
                          <div>
                            <label
                              htmlFor="title"
                              className="mb-2 block text-lg custom-text-[poppins-500-16px] text-[#111827]"
                            >
                              Title
                            </label>
                            <Field
                              id="title"
                              name="title"
                              type="text"
                              // value={title} // Controlled by Formik
                              // onChange={(e) => setTitle(e.target.value)} // Controlled by Formik
                              placeholder="Faisons connaissance...."
                              className={`w-full rounded-[15px] px-4 py-3 focus:ring-2 focus:ring-[#407EC9] shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] border-none outline-none bg-white ${errors.title && touched.title ? 'ring-2 ring-red-500' : ''}`}
                            />
                            <ErrorMessage name="title" component="p" className="mt-1 text-sm text-red-500" />
                          </div>

                          <div>
                            <label
                              htmlFor="tagline"
                              className="mb-2 block text-lg custom-text-[poppins-500-16px] text-[#111827]"
                            >
                              Tagline
                            </label>
                            <Field
                              id="tagline"
                              name="tagline"
                              type="text"
                              // value={tagline} // Controlled by Formik
                              // onChange={(e) => setTagline(e.target.value)} // Controlled by Formik
                              placeholder="Découvre plus de détails sur moi en bas"
                              className={`w-full rounded-[15px] px-4 py-3 focus:ring-2 focus:ring-[#407EC9] shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] border-none outline-none bg-white ${errors.tagline && touched.tagline ? 'ring-2 ring-red-500' : ''}`}
                            />
                            <ErrorMessage name="tagline" component="p" className="mt-1 text-sm text-red-500" />
                          </div>

                          <div>
                            <label
                              htmlFor="mentor_description" // Match Field name
                              className="mb-2 block text-lg custom-text-[poppins-500-16px] text-[#111827]"
                            >
                              Bio
                            </label>
                            <Field
                              id="mentor_description"
                              name="mentor_description"
                              as="textarea" // Use 'as' prop for textarea
                              // value={bio} // Controlled by Formik
                              onChange={(e) => { // Keep custom onChange for char count
                                setFieldValue('mentor_description', e.target.value); // Update Formik state
                                handleBioChange(e); // Update char count state
                              }}
                              placeholder="Your bio here...."
                              rows={8}
                              className={`w-full rounded-[15px] px-4 py-3 focus:ring-2 focus:ring-[#407EC9] shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] border-none outline-none bg-white ${errors.mentor_description && touched.mentor_description ? 'ring-2 ring-red-500' : ''}`}
                            />
                            <div className="flex justify-between mt-2">
                              {/* Character count display remains the same */}
                              {bioCharCount > 400 ? (
                                <p className="text-sm text-red-500">
                                  ops you have reached the maximum number of
                                  characters
                                </p>
                              ) : (
                                <p
                                  className={
                                    bioCharCount > 350
                                      ? "text-sm text-yellow-500"
                                      : "text-sm text-gray-400"
                                  }
                                >
                                  {bioCharCount > 350 &&
                                    "approaching character limit"}
                                </p>
                              )}
                              <p className="text-sm text-gray-500">
                                {bioCharCount}/400
                              </p>
                            </div>
                            <ErrorMessage name="mentor_description" component="p" className="mt-1 text-sm text-red-500" />
                          </div>
                        </div>

                        {/* Profile Photo Section (Remains outside Formik fields but inside grid) */}
                        <div>
                          <label className="mb-4 block text-lg font-medium text-[#111827]">
                            Profile Photo
                          </label>
                          <div className="flex justify-center items-center">
                            <ImageCropProvider>
                              <ProfileAvatar
                                croppedImageFile={croppedImageFile}
                                handleUploadAvatar={handleUploadAvatar}
                                isLoading={isAvatarLoading}
                                error={avatarError}
                                setCroppedImageFile={setCroppedImageFile}
                                user_avatar={AVATARGetUserAvatarUrl(user)}
                                user_fullname={`${user.firstname} ${user.lastname}`}
                              />
                            </ImageCropProvider>
                          </div>
                        </div>
                      </div>

                      {/* Social Links Section */}
                      <section className="mt-[69px] flex flex-col">
                        <div className="flex flex-row items-center justify-between gap-[17px] mb-4">
                          <h2 className="ml-[10px] custom-text-[poppins-600-32px] text-[#141414]">
                            Social Links
                          </h2>
                        </div>
                        <div className="w-full border-b-solid border-b-[1px] border-b-[rgba(0,0,0,0.25)] mb-6"></div>
                        <div className="ml-[10px] space-y-[27px]">
                          {/* LinkedIn */}
                          <div className="flex flex-col gap-[13px]">
                            <label className="w-24 custom-text-[poppins-500-16px] text-[#111827]">Linkedin</label>
                            <div className="flex flex-grow gap-[36px]">
                              <div className={`flex-grow flex items-center rounded-[15px] px-4 py-3 shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] bg-white ${errors.linkedinUrl && touched.linkedinUrl ? 'ring-2 ring-red-500' : ''}`}>
                                <LinkedInIcon />
                                <Field
                                  name="linkedinUrl"
                                  type="text"
                                  placeholder="https://linkedin.com/in/..."
                                  className="flex-grow ml-3 bg-transparent border-none outline-none"
                                />
                              </div>
                              <CustomToggle
                                label={values.showLinkedin ? "Show" : "Hide"}
                                checked={values.showLinkedin}
                                onChange={() => setFieldValue("showLinkedin", !values.showLinkedin)}
                              />
                            </div>
                            <ErrorMessage name="linkedinUrl" component="p" className="mt-1 text-sm text-red-500" />
                          </div>
                          {/* Github */}
                          <div className="flex flex-col gap-[13px]">
                            <label className="w-24 custom-text-[poppins-500-16px] text-[#111827]">Github</label>
                            <div className="flex flex-grow gap-[36px]">
                              <div className={`flex-grow flex items-center rounded-[15px] px-4 py-3 shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] bg-white ${errors.githubUrl && touched.githubUrl ? 'ring-2 ring-red-500' : ''}`}>
                                <GithubIcon />
                                <Field
                                  name="githubUrl"
                                  type="text"
                                  placeholder="https://github.com/..."
                                  className="flex-grow ml-3 bg-transparent border-none outline-none"
                                />
                              </div>
                              <CustomToggle
                                label={values.showGithub ? "Show" : "Hide"}
                                checked={values.showGithub}
                                onChange={() => setFieldValue("showGithub", !values.showGithub)}
                              />
                            </div>
                            <ErrorMessage name="githubUrl" component="p" className="mt-1 text-sm text-red-500" />
                          </div>
                          {/* Twitter */}
                          <div className="flex flex-col gap-[13px]">
                            <label className="w-24 custom-text-[poppins-500-16px] text-[#111827]">Twitter</label>
                            <div className="flex flex-grow gap-[36px]">
                              <div className={`flex-grow flex items-center rounded-[15px] px-4 py-3 shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] bg-white ${errors.twitterUrl && touched.twitterUrl ? 'ring-2 ring-red-500' : ''}`}>
                                <TwitterIcon />
                                <Field
                                  name="twitterUrl"
                                  type="text"
                                  placeholder="https://twitter.com/..."
                                  className="flex-grow ml-3 bg-transparent border-none outline-none"
                                />
                              </div>
                              <CustomToggle
                                label={values.showTwitter ? "Show" : "Hide"}
                                checked={values.showTwitter}
                                onChange={() => setFieldValue("showTwitter", !values.showTwitter)}
                              />
                            </div>
                            <ErrorMessage name="twitterUrl" component="p" className="mt-1 text-sm text-red-500" />
                          </div>
                          {/* Facebook */}
                          <div className="flex flex-col gap-[13px]">
                            <label className="w-24 custom-text-[poppins-500-16px] text-[#111827]">Facebook</label>
                            <div className="flex flex-grow gap-[36px]">
                              <div className={`flex-grow flex items-center rounded-[15px] px-4 py-3 shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] bg-white ${errors.facebookUrl && touched.facebookUrl ? 'ring-2 ring-red-500' : ''}`}>
                                <FacebookIcon />
                                <Field
                                  name="facebookUrl"
                                  type="text"
                                  placeholder="https://facebook.com/..."
                                  className="flex-grow ml-3 bg-transparent border-none outline-none"
                                />
                              </div>
                              <CustomToggle
                                label={values.showFacebook ? "Show" : "Hide"}
                                checked={values.showFacebook}
                                onChange={() => setFieldValue("showFacebook", !values.showFacebook)}
                              />
                            </div>
                            <ErrorMessage name="facebookUrl" component="p" className="mt-1 text-sm text-red-500" />
                          </div>
                          {/* Instagram */}
                          <div className="flex flex-col gap-[13px]">
                            <label className="w-24 custom-text-[poppins-500-16px] text-[#111827]">Instagram</label>
                            <div className="flex flex-grow gap-[36px]">
                              <div className={`flex-grow flex items-center rounded-[15px] px-4 py-3 shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] bg-white ${errors.instagramUrl && touched.instagramUrl ? 'ring-2 ring-red-500' : ''}`}>
                                <InstagramIcon />
                                <Field
                                  name="instagramUrl"
                                  type="text"
                                  placeholder="https://instagram.com/..."
                                  className="flex-grow ml-3 bg-transparent border-none outline-none"
                                />
                              </div>
                              <CustomToggle
                                label={values.showInstagram ? "Show" : "Hide"}
                                checked={values.showInstagram}
                                onChange={() => setFieldValue("showInstagram", !values.showInstagram)}
                              />
                            </div>
                            <ErrorMessage name="instagramUrl" component="p" className="mt-1 text-sm text-red-500" />
                          </div>
                        </div>
                      </section>

                      {/* Tags */}
                      <section className="mt-[69px] flex flex-col">
                        <div className="ml-[10px] flex flex-col w-full mt-[21px] gap-[24px]">
                          <PersonalInterestsSection
                            isLoading={false} // Assuming tags loading is handled elsewhere or not needed here
                            hasSubmitSuccess={false} // Not relevant for this integration
                            hasSubmitError={false} // Not relevant for this integration
                            handleUpdateTags={null} // Formik handles updates
                            tags={values.tags} // Use Formik values
                            setTags={(newTags) => setFieldValue('tags', newTags)} // Update Formik state
                            showUpdateButton={false} // Button is handled by main form save
                          />
                          <ErrorMessage name="tags" component="p" className="mt-1 text-sm text-red-500" />
                        </div>
                      </section>

                      {/* Avis d'utilisateurs Section*/}
                      <section className="mt-[69px] flex flex-col">
                        <div className="w-full flex flex-row items-center justify-between gap-[17px] mb-4">
                          <h2 className="ml-[10px] custom-text-[poppins-600-32px] text-[#141414]">
                            Avis d'utilisateurs
                          </h2>
                          <CustomToggle
                            label={values.feature_flag_testimonials ? "Show" : "Hide"}
                            checked={values.feature_flag_testimonials}
                            onChange={() => setFieldValue("feature_flag_testimonials", !values.feature_flag_testimonials)}
                          />
                        </div>
                        {/* Content remains the same */}
                        <div className="w-full border-b-solid border-b-[1px] border-b-[rgba(0,0,0,0.25)] mb-6"></div>
                        <div className="ml-[10px] flex flex-col w-full mt-[21px] gap-[24px]">
                          <div className="flex flex-row justify-between w-[410px]">
                            <p className="custom-text-[poppins-400-14px] text-[#6B7280]">Aucun avis pour le moment.</p>
                          </div>
                          <div className="w-full max-w-[600px]">
                            <p className="text-gray-500 custom-text-[poppins-400-14px]">
                              📢 Encouragez vos utilisateurs en leur envoyant une demande d'avis.{" "}
                              <Link href="#" className="!text-[#407EC9] !underline !font-medium">Copier le lien</Link>
                              <span className="text-gray-500"> 🔗</span> et obtenez rapidement des retours.
                            </p>
                          </div>
                        </div>
                      </section>

                      {/* Accompagnement a proposer Section */}
                      <section className="mt-[69px] flex flex-col">
                        <div className="w-full flex flex-row items-center justify-between gap-[17px] mb-4">
                          <h2 className="ml-[10px] custom-text-[poppins-600-32px] text-[#141414]">
                            Accompagnement a proposer
                          </h2>
                          <CustomToggle
                            label={values.feature_flag_mentorship_services ? "Show" : "Hide"}
                            checked={values.feature_flag_mentorship_services}
                            onChange={() => setFieldValue("feature_flag_mentorship_services", !values.feature_flag_mentorship_services)}
                          />
                        </div>
                        {/* Content remains the same */}
                        <div className="w-full border-b-solid border-b-[1px] border-b-[rgba(0,0,0,0.25)] mb-6"></div>
                        <div className="ml-[10px] flex flex-col w-full mt-[21px] gap-[24px]">
                          {/* Render existing mentorship cards */}
                          <div className="space-y-6">
                            {mentorships.map((mentorship) => (
                              <MentorshipCard
                                key={mentorship.id}
                                id={mentorship.id}
                                initialTitle={mentorship.initialTitle}
                                initialTagline={mentorship.initialTagline}
                                initialType={mentorship.initialType}
                                initialTargetAudience={mentorship.initialTargetAudience}
                                initialDuration={mentorship.initialDuration}
                                initialPrice={mentorship.initialPrice}
                                initialDescription={mentorship.initialDescription}
                                initialObjectif={mentorship.initialObjectif}
                                onSave={handleSaveMentorship}
                                onCancel={handleCancelMentorship}
                              />
                            ))}
                          </div>
                          {/* Button to add a new card */}
                          <button
                            type="button" // Prevent form submission
                            onClick={handleAddMentorship}
                            className="text-[#407EC9] underline font-medium custom-text-[poppins-500-16px] self-start mt-4 hover:text-[#3569A7]"
                          >
                            Ajouter un Accompagnement +
                          </button>
                        </div>
                      </section>

                      {/* Ignored Sections Remain Outside Formik */}
                    </Form>
                  )}
                </Formik>
              ) : (
                <div className="flex justify-center items-center w-full h-full"> {/* Centering the button */}
                  <Link href={`${process.env.REACT_APP_LANDING_URL}/join-us-as-mentor#mentor-onboarding-form-section`} className="!rounded-lg !bg-[#407EC9] px-6 py-3 !text-white transition-colors !no-underline !custom-text-[poppins-500-15px] hover:!bg-[#3569A7]">
                    Become a Mentor
                  </Link>
                </div>
              )}
              {/* Render Ignored Sections Outside Formik but inside the main conditional */}
              {(isLoggedIn && user && mentorQuery.data && mentorQuery.data.id) && (
                <>
                  {/* Derniers Labs Section */}
                  <section className="mt-[69px] flex flex-col">
                    <div className="w-full flex flex-row items-center justify-between gap-[17px] mb-4">
                      <h2 className="ml-[10px] custom-text-[poppins-600-32px] text-[#141414]">
                        Derniers Labs
                      </h2>
                      <CustomToggle
                        label={showLabs ? "Show" : "Hide"}
                        checked={showLabs}
                        onChange={() => setShowLabs(!showLabs)} // Still uses local state
                      />
                    </div>
                    <div className="w-full border-b-solid border-b-[1px] border-b-[rgba(0,0,0,0.25)] mb-6"></div>
                    <div className="ml-[10px] flex flex-col w-full mt-[21px] gap-[24px]">
                      <Link href="#" className="!text-[#407EC9] !underline !font-medium custom-text-[poppins-500-16px]">
                        Créer votre premier Lab +
                      </Link>
                    </div>
                  </section>

                  {/* Inviting users to become mentors Section */}
                  <section className="mt-[69px] flex flex-col">
                    <div className="w-full flex flex-row items-center justify-between gap-[17px] mb-4">
                      <h2 className="ml-[10px] custom-text-[poppins-600-32px] text-[#141414]">
                        Inviting users to become mentors
                      </h2>
                      <CustomToggle
                        label={showInviteMentors ? "Show" : "Hide"}
                        checked={showInviteMentors}
                        onChange={() => setShowInviteMentors(!showInviteMentors)} // Still uses local state
                      />
                    </div>
                    <div className="w-full border-b-solid border-b-[1px] border-b-[rgba(0,0,0,0.25)] mb-6"></div>
                    <div className="ml-[10px] flex flex-col w-full mt-[21px] gap-[24px]">
                      <p className="custom-text-[poppins-400-14px] text-[#6B7280]">Coming soon.</p>
                    </div>
                  </section>

                  {/* Legal information Section */}
                  <section className="mt-[69px] flex flex-col mb-10"> {/* Added mb-10 for spacing */}
                    <div className="w-full flex flex-row items-center justify-between gap-[17px] mb-4">
                      <h2 className="ml-[10px] custom-text-[poppins-600-32px] text-[#141414]">
                        Legal information of copyright of mentor content
                      </h2>
                      <CustomToggle
                        label={showLegalInfo ? "Show" : "Hide"}
                        checked={showLegalInfo}
                        onChange={() => setShowLegalInfo(!showLegalInfo)} // Still uses local state
                      />
                    </div>
                    <div className="w-full border-b-solid border-b-[1px] border-b-[rgba(0,0,0,0.25)] mb-6"></div>
                    <div className="ml-[10px] flex flex-col w-full mt-[21px] gap-[24px]">
                      <p className="custom-text-[poppins-400-14px] text-[#6B7280]">Coming soon.</p>
                    </div>
                  </section>
                </>
              )}
            </div> {/* End main content div */}
          </>
          )
      }
    </section>
  );
};

export default EditMentorProfilePage;
