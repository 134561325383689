import AvatarEditor from "react-avatar-editor";
import { useImageCropContext } from "../ImageCropProvider";
import config from "utils/config";
import { useDebugMode } from "components/DebugContext/DebugContext";

const Cropper = (props: { avatarEditorRef: AvatarEditor }) => {
  const { debugMode } = useDebugMode();

  const { image, zoom, crop, setCrop }: any = useImageCropContext();
  const { avatarEditorRef } = props;
  const size = config.cropper.size;

  return (
    <>
      <AvatarEditor
        ref={avatarEditorRef}
        scale={zoom}
        width={size}
        height={size}
        position={crop}
        showGrid={false}
        onPositionChange={(p) => setCrop(p)}
        borderRadius={size / 2}
        border={0}
        backgroundColor={"#000000"}
        onLoadFailure={() => debugMode && console.log("onLoadFailure")}
        onLoadSuccess={() => debugMode && console.log("onLoadSuccess")}
        onImageReady={() => debugMode && console.log("onImageReady")}
        image={image}
      />
    </>
  );
};

export default Cropper;
