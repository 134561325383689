import "./_index.scss";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import classNames from "classnames";
import { useImageCropContext } from "../ImageCropProvider";

export const ZoomSlider = ({ className }) => {
  const {
    zoom,
    setZoom,
    handleZoomIn,
    handleZoomOut,
    max_zoom,
    min_zoom,
    zoom_step,
  }: any = useImageCropContext();

  return (
    <div
      className={classNames(
        className,
        "cropper-zoom-slider flex items-center justify-center gap-2"
      )}
    >
      <button onClick={handleZoomOut}>
        <RemoveCircleOutline className="!w-[12px] mb-[2px]" />
      </button>
      <input
        type="range"
        name="slider"
        className="w-[116px] h-[6px] bg-[#C7C7C7] appearance-none rounded-full outline-none !cursor-pointer"
        min={min_zoom}
        max={max_zoom}
        step={zoom_step}
        value={zoom}
        onChange={(e) => {
          setZoom(Number(e.target.value));
        }}
      />
      <button onClick={handleZoomIn}>
        <AddCircleOutline className="!w-[12px] mb-[2px]" />
      </button>
      <div className="font-poppins text-[10px] font-semibold text-[#111827]">{(zoom / min_zoom * 100).toFixed(0)}%</div>
    </div>
  );
};
