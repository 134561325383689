import { CameraAlt, Check, Close } from "@mui/icons-material";
import { Avatar, Button, IconButton, Popover } from "@mui/material";
import Cropper from "components/Cropper/Cropper";
import { useImageCropContext } from "components/Cropper/ImageCropProvider";
import { ZoomSlider } from "components/Cropper/Sliders";
import { useState, useRef, useEffect } from "react";
import { readFile } from "utils/image";
import AvatarEditor from "react-avatar-editor";
import LoadingImage from "components/LoadingImage";
import { initialsFromName } from "utils/utils";
import { useTranslation } from "react-i18next";

const ProfileAvatar = ({
  user_avatar,
  user_fullname,
  setCroppedImageFile,
  croppedImageFile,
  isLoading,
  handleUploadAvatar,
  error,
}) => {
  // Crop related
  const [editAvatarAnchorEl, setEditAvatarAnchorEl] = useState(null);
  const [isEditAvatarOpen, setIsEditAvatarOpen] = useState(false);
  const handleOpenEditAvatar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEditAvatarAnchorEl(event.currentTarget);
    inputFile.current.click();
  };
  const { getProcessedImage, setImage, resetStates }: any =
    useImageCropContext();

  const [preview, setPreview] = useState(null);
  const inputFile = useRef(null);
  const avatarEditorRef = useRef<AvatarEditor>(null);

  const handleDone = async () => {
    const avatar = await getProcessedImage(avatarEditorRef);
    setCroppedImageFile(avatar);
    setPreview(window.URL.createObjectURL(avatar));
    resetStates();
    setIsEditAvatarOpen(false);
  };

  const handleFileChange = async ({ target: { files } }) => {
    const file = files && files[0];
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
    setIsEditAvatarOpen(true);
  };

  useEffect(() => {
    if (user_avatar) {
      setPreview(user_avatar);
    }
  }, [user_avatar]);
  const { t } = useTranslation();

  return (
    <>
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        value={""}
        className="hidden"
        id="avatarInput"
        accept="image/*"
        ref={inputFile}
      />
      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setIsEditAvatarOpen(false)}
        anchorEl={editAvatarAnchorEl}
        id="hint-popover"
        open={isEditAvatarOpen}
        sx={{
          "& .MuiPopover-paper": {
            width: "100%",
            maxWidth: "366px",
            padding: "11px 0 18px",
            borderRadius: "20px",
            border: "1px solid #D9DBE9",
            boxShadow:
              "0px 14px 42px 0px rgba(8, 15, 52, 0.06), 0px 7px 29px 0px rgba(100, 100, 111, 0.20)",
          },
        }}
      >
        <div className="inline-flex w-full justify-between px-[17px]">
          <div className="font-poppins text-[16px] font-semibold">
            {t("edit-photo")}{" "}
          </div>
          <IconButton
            onClick={() => setIsEditAvatarOpen(false)}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <Close className="!h-[13px] !w-[13px]" />
          </IconButton>
        </div>
        <hr className="w-full bg-[#AEAEAE] opacity-25" />
        <div className="mx-auto my-[13px] flex h-fit w-fit justify-center overflow-hidden rounded-[20px] bg-[#000]">
          <Cropper avatarEditorRef={avatarEditorRef} />
        </div>
        <div className="inline-flex w-full justify-between px-[17px]">
          <ZoomSlider className="" />
          <Button
            variant="contained"
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              width: "103px",
              height: "34px",
              backgroundColor: "#407EC9",
              borderRadius: "8px",
              color: "white",
              "&:hover": {
                backgroundColor: "#407EC9",
              },
            }}
            onClick={handleDone}
          >
            <h4 className="font-poppins text-[10px] font-medium normal-case">
              {t("save")}{" "}
            </h4>
          </Button>
        </div>
      </Popover>

      <div className="mb-[20px] h-fit max-w-[140px] place-self-center">
        {isLoading ? (
          <LoadingImage />
        ) : (
          <>
            {/* <Avatar className="!w-[137px] !h-[137px]" src={preview} alt="profile" sx={{
            border: "2px solid #407EC9",
          }} >{user_avatar ? "R M" : "R M"}</Avatar> */}
            <Avatar
              className="!h-[137px] !w-[137px] !bg-[#d9d9d9] !font-poppins !text-[52px] font-semibold !text-black"
              src={preview}
              alt="profile"
              sx={{
                border: "2px solid rgba(64, 126, 201, 1)",
              }}
            >
              {initialsFromName(user_fullname)}
            </Avatar>
            <IconButton
              className="!relative !bottom-[40px] !left-[12px] h-[33px] w-[33px]"
              size="small"
              aria-label={t("upload-picture")}
              sx={{ backgroundColor: "#407EC9" }}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={handleOpenEditAvatar}
            >
              <CameraAlt className="!h-auto !w-[15px] text-white" />
            </IconButton>

            {croppedImageFile && (
              <>
                <IconButton
                  className="!relative !bottom-[40px] !left-[60px] h-[33px] w-[33px]"
                  size="small"
                  aria-label={t("upload-picture")}
                  sx={{ backgroundColor: "#407EC9" }}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  onClick={handleUploadAvatar}
                >
                  <Check className="!h-auto !w-[18px] text-white" />
                </IconButton>
                <div className="text-center font-poppins !text-[12px] !text-red">
                  {error}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ProfileAvatar;
