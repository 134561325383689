import { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { MainLayout } from "./layouts";
import { AssignmentLayout } from "./layouts";
import EditMentorProfilePage from "views/ProfilePage/components/EditMentorProfilePage";

const IndexPage = lazy(() => import("./views"));
const ErrorPage = lazy(() => import("./views/ErrorPage"));
const ErrorLoginPage = lazy(() => import("./views/ErrorLoginPage"));
const ContactPage = lazy(() => import("./views/ContactPage"));
const LabsPage = lazy(() => import("./views/LabsPage"));
const SingleLabPage = lazy(() => import("./views/SingleLabPage"));
const LabInstancePage = lazy(() => import("./views/LabInstancePage"));
const LabInstanceResultsPage = lazy(
  () => import("./views/LabInstanceResultsPage"),
);
const AssignmentPage = lazy(() => import("./views/AssignmentPage"));
const AssessmentInstancePage = lazy(
  () => import("./views/AssessmentInstancePage"),
);
const MoreFromMentorPage = lazy(
  () => import("./views/MoreFromMentorPage"),
);
const EmbeddedCommunityPage = lazy(
  () => import("./views/EmbeddedCommunityPage"),
);
const ProfilePage = lazy(() => import("./views/ProfilePage"));
const PersonalDetailsPage = lazy(
  () => import("./views/ProfilePage/components/PersonalDetailsPage"),
);

const MessagesPage = lazy(
  () => import("./views/ProfilePage/components/MessagesPage"),
);
const FavoriteLabsPage = lazy(
  () => import("./views/ProfilePage/components/FavoriteLabsPage"),
);
const ProfileSchedulePage = lazy(
  () => import("./views/ProfilePage/components/ProfileSchedulePage"),
);
const SettingsPage = lazy(
  () => import("./views/ProfilePage/components/SettingsPage"),
);
const ManageMembershipPage = lazy(
  () => import("./views/ProfilePage/components/ManageMembershipPage"),
);
const LoginPage = lazy(() => import("./views/LoginPage"));
const Error404Page = lazy(() => import("./views/Error404Page"));

const renderRoutes = () => {
  let isAssessment = false;
  try {
    const assessment_url = new URL(process.env.REACT_APP_ASSESSMENTS_URL);
    isAssessment =
      window.location.hostname === assessment_url.hostname ||
      process.env.REACT_APP_FORCE_DEBUG_ASSESSMENTS === "true";
  } catch (e) {
    console.log(e);
    isAssessment = false;
  }

  return (
    <Routes>
      {!isAssessment ? (
        <Route path="/" element={<MainLayout />}>
          <Route index element={<IndexPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="labs" element={<LabsPage />} />
          <Route path="lab/:slug" element={<SingleLabPage />} />
          <Route path="instance/:id" element={<LabInstancePage />} />
          <Route
            path="instance/:id/results"
            element={<LabInstanceResultsPage />}
          />
          <Route path="mentor/:id" element={<MoreFromMentorPage />} />
          <Route path="profile" element={<ProfilePage />}>
            <Route path="personal-details" element={<PersonalDetailsPage />} />
            <Route path="favorite-labs" element={<FavoriteLabsPage />} />
            <Route path="schedule" element={<ProfileSchedulePage />} />
            <Route path="mentor-page" element={<EditMentorProfilePage />} />
            <Route path="messages" element={<MessagesPage />} />
            <Route path="manage-plan" element={<ManageMembershipPage />} />
            <Route path="settings" element={<SettingsPage />} />
          </Route>
          <Route path="community" element={<EmbeddedCommunityPage />} />
          <Route path="error-404" element={<Error404Page />} />
          <Route path="error-login" element={<ErrorLoginPage />} />
          <Route path="error-login/:status" element={<ErrorLoginPage />} />
        </Route>
      ) : (
        <Route path="/" element={<AssignmentLayout />}>
          <Route
            path="error-404"
            element={<Error404Page hasBackHomeButton={false} />}
          />
          <Route path="login" element={<ErrorLoginPage openAuthModalOnCTA={true} />} />
          <Route path="assignment/:id" element={<AssessmentInstancePage />} />
          <Route path=":slug" element={<AssignmentPage />} />
        </Route>
      )}
      <Route path="error" element={<ErrorPage />} />
      <Route path="error/:status" element={<ErrorPage />} />
      <Route path="oauth2" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/error-404" replace />} />
    </Routes>
  );
};

export default renderRoutes;
