import { createContext, useContext, useState } from "react";
import config from "utils/config";
import AvatarEditor from "react-avatar-editor";

export const ImageCropContext = createContext({});

const ImageCropProvider = ({
  children,
  max_zoom = 3,
  min_zoom = 1,
  zoom_step = 0.01,
}): any => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState(config.cropper.crop);
  const [zoom, setZoom] = useState(config.cropper.zoom);

  const resetStates = () => {
    setImage(null);
    setCrop(config.cropper.crop);
    setZoom(config.cropper.zoom);
  };

  const handleZoomIn = () => {
    if (zoom < max_zoom) {
      setZoom(zoom + zoom_step * 2);
    }
  };

  const handleZoomOut = () => {
    if (zoom > min_zoom) {
      setZoom(zoom - zoom_step * 2);
    }
  };

  const getProcessedImage = async (editor: AvatarEditor) => {
    const img = editor.current?.getImageScaledToCanvas().toDataURL(); // Alternative: A smaller in size (and in dimensions) is: const img = editor.current.getImage().toDataURL();
    if (!img) return;

    return fetch(img)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `img-${Date.now()}.png`, {
          type: "image/png",
        });
        return file;
      });
  };

  return (
    <ImageCropContext.Provider
      value={{
        image,
        setImage,
        zoom,
        setZoom,
        crop,
        setCrop,
        getProcessedImage,
        handleZoomIn,
        handleZoomOut,
        max_zoom,
        min_zoom,
        zoom_step,
        resetStates,
      }}
    >
      {children}
    </ImageCropContext.Provider>
  );
};

export const useImageCropContext = () => useContext(ImageCropContext);

export default ImageCropProvider;
